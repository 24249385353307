<template>
    <div>
        <span
            class="noteEntry"
            v-if="disabled || averageEntry"
        >
            {{ averageEntry ? "A" : noteEntry }}
        </span>
        <div class="noteEntryError">{{ noteError }}</div>
        <div class="noteEntryContainer">
            <b-form-input
                v-model="noteEntry"
                type="number"
                class="noteEntry"
                v-if="!disabled && !averageEntry"
                :disabled="averageEntry"
                @keypress="note_keypress"
                @input="note_input"
                size="lg"
                ref="note"
            />
            <b-form-checkbox
                switch
                size="lg"
                v-model="averageEntry"
                v-if="!disabled"
            >
                Average
            </b-form-checkbox>
        </div>
        <b-button
            class="endNoteEntry cancel"
            variant="secondary"
            size="sm"
            @click="cancel_click"
        >
            {{ disabled ? '&lt;&lt; Retour' : '&lt;&lt; Annuler' }}
        </b-button>
        <b-button
            v-if='!disabled'
            class="endNoteEntry validate"
            variant="primary"
            size="sm"
            :disabled="!noteIsValid"
            @click="validate_click"
        >
            Valider &gt;&gt;
        </b-button>

        <b-modal
            title="Confirmation de saisie"
            centered
            :static="true"
            :lazy="true"
            size="sm"
            v-if="cfgConfirmNoteEntryByPopup"
            v-model="showConfirmationModal"
            ok-variant="success"
            ok-title="Oui"
            cancel-variant="warning"
            cancel-title="non, je veux corriger ma note"
            button-size="lg"
            >
            <template #modal-footer="{cancel}">
                <b-button size="sm" variant="warning" @click="cancel()">Non, je veux corriger ma note</b-button>
                <b-button size="lg" variant="success" @click="validate_click">Oui</b-button>
            </template>
            Vous avez saisi la note suivante
            <div class="noteEntryContainer">
            <div class="noteCheck">{{ averageEntry ? "A" : noteEntry }}</div></div>
            <div>confirmez-vous cette note ?</div>
            <i class="warning">ATTENTION ! Une fois confirmée, votre note ne pourra plus être modifiée</i>

        </b-modal>
    </div>
</template>

<script>

export default {
    props: {
        value: { type: String, default: null },
        minValue: { type: Number, default: 0 },
        maxValue: { type: Number, default: 100 },
        disabled: { type: Boolean, default: false },
    },
    data(){
        return {
            previousEntry: (this.value == "A" || this.value == "a") ? "" : this.value,
            noteEntry: (this.value == "A" || this.value == "a") ? null : this.value ,
            averageEntry: (this.value == "A" || this.value == "a"),
            //noteIsValid: false,
            noteError: '',
            showConfirmationModal: false,
        };
    },
    computed:{
        note:{
            get:() => this.averageEntry ? "A" : this.noteEntry,
            set:(newValue) =>{
                if(newValue == "A" || newValue == "a")
                {
                    this.averageEntry = true;
                    this.noteEntry = null;
                    this.noteError = '';
                } else {
                    const regExp = new RegExp('^([Aa]|[1-9][0-9]|[0-9]|100)$');
                    if(regExp.test(newValue))
                    {
                        this.noteEntry = newValue;
                        this.averageEntry = false;
                        this.noteError = '';
                    }
                    else{
                        this.noteEntry = null;
                        this.averageEntry = false;
                        this.noteError = "Note non valide.";
                    }
                }
            }
        },
        noteIsValid(){
            return (this.averageEntry || (this.noteEntry != null && this.noteEntry != "")) && (this.noteError == null || this.noteError == "");
        }
    },
    methods:{
        note_keypress(e){
            this.noteError = '';
            var regExp = new RegExp ('[0123456789]');
            if(!regExp.test(e.key))
            {
              if(e.key == 'Enter')
              {
                this.validate_click();
              } else {
                e.preventDefault();
                this.noteError = "La note doit être un nombre entier (sans virgule) compris entre " + this.minValue + " et " + this.maxValue + ".";
                this.not
              }
            }
        },
        note_input(){
            this.noteError = '';
            if(!this.averageEntry && this.noteEntry != null && this.noteEntry != "")
            {
                var numEntry = parseInt(this.noteEntry);
                if(numEntry < this.minValue || numEntry > this.maxValue){
                    this.noteError = "La note doit être comprise entre " + this.minValue + " et " + this.maxValue + ".";
                    this.$nextTick(()=> {
                        this.noteEntry = this.previousEntry;
                    });
                }
                else
                {
                    this.previousEntry = this.noteEntry;
                }
            }
       },
        validate_click(){
            if(this.cfgConfirmNoteEntryByPopup && !this.showConfirmationModal){
                this.showConfirmationModal = true;
                return true;
            }
            this.$emit('change', { figure: this.averageEntry ? "A" : parseInt(this.noteEntry) });
        },
        cancel_click(){
            this.$emit('cancel');
        }
        ,
        focus()
        {
            this.$refs.note.focus();
        }
    },
    mounted(){
        this.showConfirmationModal = false;
        this.focus();
    }
}
</script>

<style scoped>
    .noteEntryContainer{
        flex-direction: column;
    }
    input[type="checkbox"]{
        width:20px !important;
        height:20px !important;
    }
    modal-body{
        display: flex 1 1;
        justify-content: center;
        align-items: center;
    }
    i.warning{
        color: var(--danger);
        font-size: 0.8em;
    }
</style>
